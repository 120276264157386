.hero2-header5 {
  width: 100%;
  height: 60vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.hero2-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.qhse-heading {
  color: #000000;
  padding-top: 50px;

}
.hero2-qhse{
  padding-top: 70px;
  padding: 30px;
  max-width: 1000px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}
.hero2-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 30px;
}
.hero2-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.hero2-column {
  gap: 24px;
  width: auto;
  display: flex;
  z-index: 1;
  max-width: 560px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.hero2-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeInLeftBig;
  flex-direction: column;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.hero2-text {
  color: rgb(255, 255, 255);
  font-family: "Poppins";
  text-align: center;
}
.hero2-text1 {
  color: #ffffff;
  text-align: center;
}
.hero2-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hero2-container1 {
  display: flex;
  align-items: flex-start;
}
.hero2-button {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.hero2-text2 {
  font-family: "Poppins";
}
.hero2-container2 {
  display: flex;
  align-items: flex-start;
}
.hero2-button1 {
  width: 157px;
  height: 44px;
}
.hero2-text3 {
  font-family: "Poppins";
}
@media(max-width: 767px) {
  .hero2-header5 {
    justify-content: center;
  }
  .hero2-max-width {
    justify-content: center;
  }
  .hero2-column {
    width: 100%;
  }
  .hero2-text {
    text-align: center;
  }
  .hero2-text1 {
    text-align: center;
  }
  .hero2-actions {
    width: 100%;
    align-self: flex-start;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .hero2-actions {
    flex-direction: column;
  }
  .hero2-container1 {
    width: 100%;
  }
  .hero2-button {
    width: 100%;
  }
  .hero2-container2 {
    width: 100%;
  }
  .hero2-button1 {
    width: 100%;
  }
  .hero2-qhse{
    padding-top: 50px;
    
  }
}

.career-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.career-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}
.career-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.career-content {
  align-self: stretch;
  align-items: flex-start;
}
.career-heading1 {
  align-self: flex-start;
  font-family: "Poppins";
}
.career-heading2 {
  font-family: "Poppins";
}
.career-heading3 {
  font-family: "Poppins";
}
.career-heading4 {
  font-family: "Poppins";
}
.career-container2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
}
.career-max-width1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.career-container3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.career-title {
  text-align: center;
  font-family: "Poppins";
}
.career-text {
  text-align: center;
  font-family: "Poppins";
}
.career-actions {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.career-button {
  flex: 1;
  text-decoration: none;
}
.career-text1 {
  font-family: "Poppins";
}
.career-button1 {
  flex: 1;
  width: 50%;
  height: 41px;
  text-decoration: none;
}
.career-text2 {
  font-family: "Poppins";
}
@media(max-width: 767px) {
  .career-actions {
    width: 100%;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .career-actions {
    flex-direction: column;
  }
  .career-button {
    width: 100%;
  }
  .career-button1 {
    width: 100%;
  }
}

:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-theme-accent1: #F3E8E2;
  --dl-color-theme-accent2: #D8BFAF;
  --dl-radius-radius-round: 50%;
  --dl-color-theme-primary1: #D1510A;
  --dl-color-theme-primary2: #923A06;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-theme-secondary1: #F3E8E2;
  --dl-color-theme-secondary2: #E0D1CB;
  --dl-space-space-threeunits: 48px;
  --dl-color-theme-neutral-dark: #191818;
  --dl-radius-radius-cardradius: 8px;
  --dl-color-theme-neutral-light: #FBFAF9;
  --dl-radius-radius-imageradius: 8px;
  --dl-radius-radius-inputradius: 24px;
  --dl-radius-radius-buttonradius: 24px;
  --dl-space-space-oneandhalfunits: 24px;
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  outline: none;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.button-main {
  gap: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #040731;
}
.button-main:hover {
  opacity: 0.75;
}
.quick-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  border-right-width: 1px;
}
.quick-links:hover {
  opacity: 0.5;
}
.read-more {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
}
.read-more:hover {
  opacity: 0.5;
}
.button-white {
  color: #000000;
  background-color: #ffffff;
}
.symptom {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}
.symptom:hover {
  opacity: 0.5;
}
.letter {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 60px;
  transition: 0.3s;
  align-items: center;
  aspect-ratio: 1;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-round);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #E9E9EB;
}
.letter:hover {
  opacity: 0.5;
}
.book-type {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.book-type:hover {
  color: white;
  background-color: black;
}
.book-input {
  width: 100%;
  padding-top: 0px;
  border-color: #B4B9C6;
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
  border-bottom-width: 1px;
}
.arrow {
  width: 60px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.arrow:hover {
  opacity: 0.5;
}
.legal-link {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s;
}
.legal-link:hover {
  opacity: 0.5;
}
.social {
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(236, 233, 234, 0.2);
}
.social:hover {
  opacity: 0.5;
}
.letter-active {
  color: white;
  background-color: #040731;
}
.search-result {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}
.result-text {
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
}
.more-results {
  color: white;
  background-color: #040731;
}
.invisible {
  opacity: 0;
  animation: fade-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transform-origin: 0 50%;
}
.thq-input {
  color: var(--dl-color-theme-neutral-dark);
  cursor: auto;
  padding: 0.5rem 1rem;
  align-self: stretch;
  text-align: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-inputradius);
  background-color: var(--dl-color-theme-neutral-light);
}
.thq-button-filled {
  color: var(--dl-color-theme-secondary1);
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  white-space: nowrap;
  border-color: var(--dl-color-theme-primary1);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-primary1);
}
.thq-button-filled:hover {
  color: var(--dl-color-theme-secondary2);
  border-color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-button-outline {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  border: 1px solid;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  white-space: nowrap;
  border-color: var(--dl-color-theme-primary1);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.thq-button-outline:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-button-flat {
  gap: var(--dl-space-space-halfunit);
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.thq-button-flat:hover {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  border-color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-heading-1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
}
.thq-heading-2 {
  font-size: 35px;
  font-weight: 600;
  line-height: 1.5;
}
.thq-heading-3 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.5;
}
.thq-body-large {
  font-size: 18px;
  line-height: 1.5;
}
.thq-body-small {
  font-size: 16px;
  line-height: 1.5;
}
.thq-team-image-round {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.thq-section-padding {
  width: 100%;
  display: flex;
  /* padding: var(--dl-space-space-fiveunits); */
  position: relative;
  align-items: center;
  flex-direction: column;
 
}
.thq-section-max-width {
  width: 100%;
  /* max-width: var(--dl-size-size-maxwidth); */
}
.thq-img-ratio-1-1 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-16-9 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-4-3 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-4-6 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/6;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-round {
  width: 100%;
  border-radius: var(--dl-radius-radius-round);
}
.thq-flex-column {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.thq-flex-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
}
.thq-grid-6 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.thq-grid-5 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.thq-card {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: stretch;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
}
.thq-box-shadow {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
}
.thq-grid-3 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.thq-grid-4 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.thq-grid-2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr;
}
.thq-checkbox {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.thq-select {
  cursor: pointer;
  appearance: none;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-inputradius);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg width%3D%2220%22 height%3D%2220%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 20 20%22 fill%3D%22%23000%22%3E%3Cpath d%3D%22M4.293 7.293a1 1 0 011.414 0L10 11.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 8px center;
}
.thq-divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: var(--dl-color-theme-neutral-dark);
}
.thq-icon-small {
  width: 24px;
  height: 24px;
}
.thq-button-icon {
  fill: var(--dl-color-theme-secondary1);
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Noto Sans;
  border-radius: var(--dl-radius-radius-buttonradius);
}
.thq-button-icon:hover {
  fill: var(--dl-color-theme-secondary2);
}
.thq-icon-medium {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.thq-icon-x-small {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.thq-link {
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
}
.thq-link:hover {
  opacity: 0.8;
}
.thq-input::placeholder {
  text-align: center;
  vertical-align: middle;
}
.Content {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 991px) {
  .letter {
    max-width: calc(100% / 6 - 20px);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .thq-flex-row {
    flex-direction: column;
  }
  .thq-grid-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .arrow {
    width: 40px;
  }
  .thq-section-padding {
    padding: var(--dl-space-space-threeunits);
  }
  .thq-flex-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .thq-flex-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .thq-grid-6 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr 1fr;
  }
  .thq-grid-5 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr 1fr;
  }
  .thq-card {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .thq-grid-3 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-4 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-2 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr;
  }
}
@media(max-width: 557px) {
  .thq-section-padding {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .thq-flex-column {
    gap: var(--dl-space-space-unit);
  }
  .thq-flex-row {
    gap: var(--dl-space-space-unit);
  }
  .thq-grid-6 {
    grid-gap: var(--dl-space-space-unit);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-5 {
    grid-gap: var(--dl-space-space-unit);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-3 {
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    grid-template-columns: 1fr;
  }
  .thq-grid-4 {
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .thq-grid-2 {
    grid-gap: var(--dl-space-space-unit);
  }
  .hero2-text1{
    font-size: 10px;
}
}