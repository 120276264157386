.services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1502583794981-4610d89ce708?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIyfHxFbGVjdHJpY2FsfGVufDB8fHx8MTcwNzMwMzY0Mnww&ixlib=rb-4.0.3&w=1500');
  background-position: center;
}
.services-text {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  letter-spacing: 2px;
}
.services-text01 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  max-width: 450px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.services-button {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.services-button:hover {
  transform: scale(1.02);
}
.services-testimonial {
  width: 100%;
  display: flex;
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1518181835702-6eef8b4b2113?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHxFbGVjdHJpY2FsfGVufDB8fHx8MTcwNzMwMzY0Mnww&ixlib=rb-4.0.3&w=1500");
  background-position: center;
}
.services-container01 {
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: stretch;
  justify-content: space-between;
}
.services-container02 {
  flex: 2;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.services-container03 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.services-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.services-text02 {
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-twounits);
}
.services-gallery {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.services-container04 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-container05 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.services-container06 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-container07 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-container08 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.services-container09 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-container10 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media(max-width: 991px) {
  .services-container01 {
    flex-direction: column;
  }
  .services-container02 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .services-text02 {
    max-width: 100%;
  }
  .services-container09 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .services-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-container01 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-text02 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .services-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-container05 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .services-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .services-container01 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .services-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .services-text02 {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .services-gallery {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .services-container04 {
    width: auto;
  }
}
