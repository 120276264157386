:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  outline: none;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.button-main {
  gap: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #ffffff;
}
.button-main:hover {
  opacity: 0.75;
}
.quick-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  border-right-width: 1px;
}
.quick-links:hover {
  opacity: 0.5;
}
.read-more {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
}
.read-more:hover {
  opacity: 0.5;
}
.button-white {
  color: #ffffff;
  background-color: #ffffff;
}
.symptom {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #ffffff;
}
.symptom:hover {
  opacity: 0.5;
}
.letter {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 60px;
  transition: 0.3s;
  align-items: center;
  aspect-ratio: 1;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-round);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #e9e9eb;
}
.letter:hover {
  opacity: 0.5;
}
.book-type {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.book-type:hover {
  color: white;
  background-color: black;
}
.book-input {
  width: 100%;
  padding-top: 0px;
  border-color: #b4b9c6;
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
  border-bottom-width: 1px;
}
.arrow {
  width: 60px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.arrow:hover {
  opacity: 0.5;
}
.legal-link {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s;
}
.legal-link:hover {
  opacity: 0.5;
}
.social {
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(236, 233, 234, 0.2);
}
.social:hover {
  opacity: 0.5;
}
.letter-active {
  color: white;
  background-color: #ffffff;
}
.search-result {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #e9e9eb;
}
.result-text {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.more-results {
  color: white;
  background-color: #ffffff;
}
.invisible {
  opacity: 0;
  animation: fade-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  transform-origin: 0 50%;
}
.Content {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media (max-width: 991px) {
  .letter {
    max-width: calc(100% / 6 - 20px);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
@media (max-width: 767px) {
  .arrow {
    width: 40px;
  }
}
.navbar-container {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.navbar-navbar {
  width: 1942px;
  display: flex;
  z-index: 100;
  max-width: 100%;
  min-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.navbar-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-navlink {
  display: contents;
}
.navbar-logo {
  width: 165px;
  object-fit: cover;
  text-decoration: none;
}
.navbar-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-link {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.navbar-link:hover {
  opacity: 0.5;
}
.navbar-link1 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.navbar-link1:hover {
  opacity: 0.5;
}
.navbar-link2 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.navbar-link2:hover {
  opacity: 0.5;
}
.navbar-link3 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.navbar-link3:hover {
  opacity: 0.5;
}
.navbar-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-phone {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}
.navbar-image {
  width: 18px;
  object-fit: cover;
}
.navbar-link4 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}
.navbar-book {
  text-decoration: none;
}
.navbar-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.navbar-text2 {
  font-family: "Inter";
}
.navbar-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-image1 {
  height: 2rem;
}
.navbar-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-text3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-text4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-navlink1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-navlink2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-phone1 {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}
.navbar-image2 {
  width: 18px;
  object-fit: cover;
}
.navbar-link5 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}
.navbar-book1 {
  text-decoration: none;
}
.navbar-text5 {
  font-family: Inter;
}
.navbar-root-class-name {
  width: 100%;
  align-self: center;
}
.navbar-root-class-name1 {
  width: 100%;
}
.navbar-root-class-name2 {
  width: 100%;
}
.navbar-root-class-name3 {
  width: 100%;
}
.navbar-root-class-name4 {
  width: 100%;
}
.navbar-root-class-name5 {
  width: 100%;
}
@media (max-width: 991px) {
  .navbar-navbar {
    z-index: 1000;
  }
  .navbar-links {
    display: none;
  }
  .navbar-right {
    display: none;
  }
  .navbar-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #ffffff;
  }
  .navbar-icon2 {
    fill: white;
  }
  .navbar-text5 {
    font-family: Inter;
  }
}
@media (max-width: 767px) {
  .navbar-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .navbar-burger-menu {
    display: flex;
  }
}
@media (max-width: 479px) {
  .navbar-navbar {
    padding: var(--dl-space-space-unit);
  }
  .navbar-mobile-menu {
    padding: 16px;
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
html {
  font-family: Poppins;
  font-size: 16px;
}

body {
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.15;
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-gray-white);
}
html {
  line-height: 1.15;
}
body {
  margin: 0;
}
* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
p,
li,
ul,
pre,
div,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
blockquote,
figcaption {
  margin: 0;
  padding: 0;
}
button {
  background-color: transparent;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focus,
[type="button"]:-moz-focus,
[type="reset"]:-moz-focus,
[type="submit"]:-moz-focus {
  outline: 1px dotted ButtonText;
}
a {
  color: inherit;
  text-decoration: inherit;
}
input {
  padding: 2px 4px;
}
img {
  display: block;
}
html {
  scroll-behavior: smooth;
}
