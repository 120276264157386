.projects-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.projects-news {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 200px;
}
.projects-heading {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.projects-text {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.projects-text1 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.projects-list {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  flex-direction: row;
}
.projects-item {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #040731;
}
.projects-image {
  width: 100%;
  height: 275px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-image01 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-content {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.projects-quick-description {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text2 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image02 {
  width: 12px;
  object-fit: cover;
}
.projects-item1 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f7;
}
.projects-image03 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-image04 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-content1 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date1 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.projects-quick-description1 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text3 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image05 {
  width: 12px;
  object-fit: cover;
}
.projects-item2 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f7;
}
.projects-image06 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-image07 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-content2 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date2 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.projects-quick-description2 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text4 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image08 {
  width: 12px;
  object-fit: cover;
}
.projects-list1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  flex-direction: row;
}
.projects-item3 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #040731;
}
.projects-image09 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 275px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-content3 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date3 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.projects-quick-description3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text5 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image10 {
  width: 12px;
  object-fit: cover;
}
.projects-item4 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f7;
}
.projects-image11 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-image12 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-content4 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date4 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.projects-quick-description4 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text6 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image13 {
  width: 12px;
  object-fit: cover;
}
.projects-item5 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f7;
}
.projects-image14 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects-image15 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-content5 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.projects-details5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects-date5 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.projects-quick-description5 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.projects-text7 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.projects-image16 {
  width: 12px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .projects-list {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .projects-item {
    height: auto;
  }
  .projects-image {
    height: 280px;
  }
  .projects-content {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description {
    font-size: 18px;
  }
  .projects-item1 {
    height: auto;
  }
  .projects-image03 {
    height: 280px;
  }
  .projects-content1 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description1 {
    font-size: 18px;
  }
  .projects-item2 {
    height: auto;
  }
  .projects-image06 {
    height: 280px;
  }
  .projects-content2 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description2 {
    font-size: 18px;
  }
  .projects-list1 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .projects-item3 {
    height: auto;
  }
  .projects-image09 {
    height: 280px;
  }
  .projects-content3 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description3 {
    font-size: 18px;
  }
  .projects-item4 {
    height: auto;
  }
  .projects-image11 {
    height: 280px;
  }
  .projects-content4 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description4 {
    font-size: 18px;
  }
  .projects-item5 {
    height: auto;
  }
  .projects-image14 {
    height: 280px;
  }
  .projects-content5 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .projects-quick-description5 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .projects-news {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .projects-heading {
    gap: var(--dl-space-space-unit);
  }
  .projects-text {
    font-size: 30px;
    line-height: 36px;
  }
  .projects-text1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .projects-date {
    font-size: 10px;
    line-height: 15px;
  }
  .projects-date1 {
    font-size: 10px;
    line-height: 15px;
  }
  .projects-date2 {
    font-size: 10px;
    line-height: 15px;
  }
  .projects-date3 {
    font-size: 10px;
    line-height: 15px;
  }
  .projects-date4 {
    font-size: 10px;
    line-height: 15px;
  }
  .projects-date5 {
    font-size: 10px;
    line-height: 15px;
  }
}

.card {
  height: 100%;
}

.card-img-top {
  height: 250px; /* Adjust the height of the card image */
  object-fit: cover;
}

.card-title {
  font-size: 1.5rem; /* Adjust the font size of the card title */
}

.card-text {
  font-size: 1rem; /* Adjust the font size of the card text */
}
