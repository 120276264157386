* {
  font-family: 'Poppins', sans-serif;
}

.team-section {
  padding: 80px 0;
  background-color: #f5f9ff;
}

.team-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Featured Leader Style */
.featured-leader {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(360px, 1fr) 1.5fr;
  gap: 30px;
  padding: 40px;
}

.featured-leader-image-container {
  width: 360px; /* Exact width of the image */
  height: 480px; /* Exact height of the image */
  margin: 0 auto;
}

.featured-leader-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
}

.featured-leader-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.featured-leader-name {
  font-size: 32px;
  font-weight: 600;
  color: #1a237e;
  margin: 0 0 12px 0;
}

.featured-leader-position {
  font-size: 22px;
  color: #444;
  margin: 0 0 24px 0;
  padding-bottom: 24px;
  border-bottom: 2px solid #1a237e;
  font-weight: 500;
}

.featured-leader-quote {
  font-style: italic;
  color: #444;
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 24px;
}

/* Other Team Members Style */
.other-members {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.team-card {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
  align-items: start;
}

.team-image-container-small {
  width: 200px; /* Reduced size while maintaining aspect ratio */
  height: 271px; /* Calculated based on original aspect ratio (822/608 * 200) */
}

.team-image-small {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
}

.team-info-small {
  flex: 1;
}

.team-name-small {
  font-size: 24px;
  font-weight: 600;
  color: #1a237e;
  margin: 0 0 8px 0;
}

.team-position-small {
  font-size: 18px;
  color: #666;
  margin: 0 0 16px 0;
  font-weight: 500;
}

.team-description {
  color: #444;
  line-height: 1.6;
  margin: 0;
  font-size: 16px;
}

.section-title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #1a237e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.section-subtitle {
  text-align: center;
  font-size: 18px;
  color: #666;
  max-width: 800px;
  margin: -30px auto 50px;
}

@media (max-width: 992px) {
  .featured-leader {
      grid-template-columns: 1fr;
      padding: 20px;
  }
  
  .featured-leader-image-container {
      width: 300px;
      height: 400px;
  }
  
  .team-card {
      grid-template-columns: 1fr;
      text-align: center;
  }
  
  .team-image-container-small {
      margin: 0 auto;
  }
}