.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #000000;
}
.footer-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.footer-brand {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: column;
}
.footer-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.footer-image {
  width: 165px;
  height: 95px;
  object-fit: cover;
}
.footer-image1 {
  width: 90px;
  height: 58px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
}
.footer-image2 {
  width: 113px;
  height: 59px;
  object-fit: cover;
}
.footer-text {
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 21px;
}
.footer-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}
.footer-image3 {
  width: 24px;
  object-fit: cover;
}
.footer-image4 {
  width: 24px;
  object-fit: cover;
}
.footer-image5 {
  width: 24px;
  object-fit: cover;
}
.footer-legal {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.footer-right {
  gap: 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-list {
  gap: var(--dl-space-space-unit);
  flex: 1;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-list1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-header {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.footer-links {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}
.footer-link:hover {
  opacity: 0.5;
}
.footer-link1 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.footer-link1:hover {
  opacity: 0.5;
}
.footer-link2 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.footer-link2:hover {
  opacity: 0.5;
}
.footer-legal1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.footer-row {
  display: flex;
}
.footer-copyright5 {
  color: rgb(255, 255, 255);
  font-size: 12px;
}



.footer-root-class-name3 {
  margin-top: 200PX;
}

@media(max-width: 991px) {
  .footer-footer {
    gap: 100px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-left {
    padding-right: 0px;
  }
  .footer-socials {
    display: none;
  }
  .footer-legal {
    display: none;
  }
  .footer-right {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .footer-legal1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-direction: column;
  }
  .footer-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .footer-image {
    width: 110px;
  }
  .footer-text {
    font-size: 12px;
    line-height: 18px;
  }
}
@media(max-width: 479px) {
  .footer-image1 {
    width: 83px;
    height: 44px;
  }
  .footer-image2 {
    width: 108px;
    height: 46px;
  }
  .footer-text {
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 18px;
  }
}
