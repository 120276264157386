.clients-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-news {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 200px;
}
.clients-heading {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.clients-text {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.clients-text1 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.clients-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.clients-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.clients-feature-card {
  width: 100%;
  height: 369px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-feature-card:hover {
  transform: scale(1.02);
}
.clients-text2 {
  margin-bottom: var(--dl-space-space-twounits);
}
.clients-image {
  width: 256px;
  height: 167px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.clients-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-feature-card1:hover {
  transform: scale(1.02);
}
.clients-text3 {
  margin-bottom: var(--dl-space-space-twounits);
}
.clients-image1 {
  width: 265px;
  height: 247px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.clients-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-feature-card2:hover {
  transform: scale(1.02);
}
.clients-text4 {
  margin-bottom: var(--dl-space-space-twounits);
}
.clients-image2 {
  width: 277px;
  height: 195px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.clients-feature-card3 {
  width: 100%;
  height: 369px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-feature-card3:hover {
  transform: scale(1.02);
}
.clients-text8 {
  margin-bottom: var(--dl-space-space-twounits);
}
.clients-image3 {
  width: 269px;
  height: 192px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
@media(max-width: 1360px) {
  .clients-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    animation-direction: column;
  }
  .clients-container1 {
    grid-template-columns: 1fr 1fr;
  }
  .clients-feature-card {
    height: 365px;
  }
  .clients-feature-card1 {
    height: 365px;
  }
  .clients-feature-card2 {
    height: 365px;
  }
  .clients-feature-card3 {
    height: 365px;
  }
}
@media(max-width: 991px) {
  .clients-container1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .clients-news {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .clients-heading {
    gap: var(--dl-space-space-unit);
  }
  .clients-text {
    font-size: 30px;
    line-height: 36px;
  }
  .clients-text1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .clients-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    animation-direction: column;
  }
  .clients-container1 {
    grid-template-columns: 1fr;
  }
  .clients-image {
    width: 345px;
    height: 234px;
  }
}
@media(max-width: 479px) {
  .clients-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .clients-container1 {
    width: 307px;
    grid-gap: var(--dl-space-space-halfunit);
  }
  .clients-image {
    width: 261px;
    height: 50%;
  }
}
