.gallery-card2-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card2-container {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  z-index: 1;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #00000096;
}

.gallery-card2-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card2-text {
  color: var(--dl-color-gray-white);
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.gallery-card2-text1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.gallery-card2-text2 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.gallery-card2-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.gallery-card2-root-class-name {
  flex: 1;
}

.gallery-card2-root-class-name4 {
  flex: 1;
  height: 100%;
}

@media (max-width: 767px) {
  .gallery-card2-gallery-card {
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .gallery-card2-container {
    max-width: auto;
  }
  .gallery-card2-image {
    top: 0px;
    left: 0px;
    right: auto;
    bottom: auto;
  }
}
