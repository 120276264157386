.book-book {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: 120px;
  border-color: #B4B9C6;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
}
.book-heading {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.book-text {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.book-text1 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.book-link {
  text-decoration: underline;
}
.book-link1 {
  text-decoration: underline;
}
.book-form {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.book-types {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.book-inputs {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.book-lower {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.book-button {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.book-book1 {
  width: 100%;
}
.book-text6 {
  font-size: 10px;
  max-width: 700px;
  text-align: left;
  line-height: 15px;
}

@media(max-width: 767px) {
  .book-book {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .book-heading {
    gap: var(--dl-space-space-unit);
  }
  .book-text {
    font-size: 24px;
  }
  .book-text1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .book-form {
    gap: var(--dl-space-space-threeunits);
  }
  .book-types {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .book-inputs {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .book-types {
    flex-direction: column;
  }
}
