.project-details-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.project-details-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.project-details-image {
  width: 585px;
  height: 672px;
  object-fit: cover;
}
.project-details-container1 {
  width: 397px;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.project-details-text {
  font-size: 3rem;
}
.project-details-text01 {
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.project-details-text02 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.project-details-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.project-details-text16 {
  color: #000000;
  width: 100%;
  font-size: 1.15rem;
  text-align: center;
}
@media(max-width: 991px) {
  .project-details-hero {
    width: 100%;
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .project-details-image {
    order: 2;
  }
  .project-details-container1 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .project-details-text {
    text-align: center;
  }
  .project-details-text01 {
    text-align: center;
  }
  .project-details-text02 {
    text-align: center;
    padding-left: var(--dl-space-space-fourunits);
  }
  .project-details-text16 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .project-details-hero {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .project-details-image {
    width: 80%;
  }
  .project-details-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .project-details-hero {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .project-details-image {
    height: 505px;
  }
  .project-details-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .project-details-text02 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .project-details-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
